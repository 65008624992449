import React, { useState } from 'react'
import logo from './assets/carnival-logo.svg'
import line from './assets/line.svg'
import star from './assets/star.svg'
import Form from './RegistrationForm'

function App() {
  const [submitted, setSubmitted] = useState(false)
  const [registered, setRegistered] = useState(false)

  const handleSubmit = (formData) => {
    console.log(formData)
    setSubmitted(true)
    fetch('https://tmp.miwu.land/api/', {
      method: 'POST',
      body: JSON.stringify({ ...formData, persons: formData.persons + 1 }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) setRegistered(true)
        else console.log(res.message)
      })
  }
  return (
    <div className="App">
      <header>
        <div
          id="banner"
          className="banner flex flex-row justify-center mt-4"
          style={{
            backgroundColor: '#8e021d',
            opacity: '.88',
          }}
        >
          <div className="w-40 sm:w-4/12 md:w-1/4 mr-0 sm:mr-6 md:mr-12 lg:mr-20">
            {' '}
            &nbsp;
          </div>
          <div
            className="flex-1 flex-col text-white pt-4 pb-2 mr-4"
            style={{
              fontFamily: 'Bebas Neue, cursive',
            }}
          >
            <div className="text-left text-4xl md:text-5xl lg:text-6xl pb-0.5 sm:w-11/12 md:w-11/12 md:pl-40 lg:pl-44 border-b-2 lg:border-b-4 border-b-white">
              RIO DE JANEIRO
            </div>
            <div className="text-left text-lg md:text-2xl lg:text-3xl uppercase pt-1 md:pl-44 lg:pl-48">
              Aberto 01 de Dezembro de 2021
            </div>
          </div>
        </div>
        <div
          id="logo"
          className="absolute left-3 sm:left-11 md:left-52 lg:left-1/4 top-0 p-3 w-32 h-32 md:w-36 md:h-36 lg:w-40 lg:h-40 shadow-2xl drop-shadow-2xl"
          style={{
            backgroundColor: '#d53439',
            boxShadow: '0 .75em 1.45em rgba(0, 0, 0, .45)',
          }}
        >
          <img src={logo} />
        </div>
      </header>
      <div className="flex flex-row justify-center">
        <div className="lg:max-w-3/4 px-4 sm:px-10 md:px-12">
          <section id="intro">
            <div className="mb-6 sm:mb-7 lg:mb-10">
              <div className="mt-12 sm:mt-16 lg:mt-20 mb-4 sm:mb-8 pr-3 text-left flex flex-row flex-wrap">
                <div className="mr-2">
                  <h1 className="text-3xl lg:text-4xl text-left">
                    Einladung&nbsp;zur
                  </h1>
                </div>
                <div className="mr-2">
                  <h1 className="text-3xl lg:text-4xl text-left">Eröffnung</h1>
                </div>
                <div className="mr-2">
                  <h1 className="text-3xl lg:text-4xl text-left">von</h1>
                </div>
                <div>
                  <h1 className="text-3xl lg:text-4xl text-left">
                    Rio&nbsp;de&nbsp;Janeiro
                  </h1>
                </div>
              </div>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.{' '}
              </p>
              <p>
                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea commodo consequat.
              </p>
            </div>
            <div className="flex flex-row items-center mx-3 mb-5 md:mb-8 lg:mb-9 lg:pb-5">
              <div className="flex-1">
                <img
                  src={line}
                  className="max-w-10"
                  style={{ width: '100%', height: '.2em' }}
                />
              </div>
              <div className="flex-shrink-0 w-7 px-1 mx-3">
                <img src={star} />
              </div>
              <div className="flex-1">
                <img src={line} style={{ width: '100%', height: '.2em' }} />
              </div>
            </div>
          </section>

          <section id="registration">
            <div className="px-3">
              {registered ? (
                <div>
                  <h1 className="text-2xl lg:text-3xl text-left mb-4">
                    Vielen Dank!
                  </h1>
                  <p className="ml-4 text-lg">
                    Wir freuen uns auf den Tanz mit Ihnen.
                  </p>
                </div>
              ) : (
                <Form onSubmit={handleSubmit} submitted={submitted} />
              )}
            </div>
          </section>
        </div>
      </div>
      <footer className="text-gray-700 mb-4">
        <div className="flex flex-wrap px-20 mt-11 justify-center text-sm">
          <a
            className="mx-4"
            href="https://www.miniatur-wunderland.de/service/impressum/"
            alt="Impressum"
          >
            Impressum
          </a>
          <a
            className="mx-4"
            href="https://www.miniatur-wunderland.de/service/datenschutz/"
            alt="Datenschutz"
          >
            Datenschutz
          </a>
          <a
            className="mx-4"
            href="https://www.miniatur-wunderland.de"
            alt="Homepage"
          >
            Miniatur&nbsp;Wunderland
          </a>
        </div>
        <div className="text-center text-sm mt-1"></div>
      </footer>
    </div>
  )
}

export default App
