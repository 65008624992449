import React from 'react'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'

type formData = {
  company: string
  mediaType: string
  name: string
  email: string
  persons: number
  parking: boolean
}
function SignUpForm({
  onSubmit,
  submitted,
}: {
  // eslint-disable-next-line no-unused-vars
  onSubmit: (arg0: formData) => void
  submitted: boolean
}) {
  // const [addressError, setAddressError] = useState(false)

  // eslint-disable-next-line no-unused-vars
  const handleSubmit = (person: formData, formError: any) => {
    onSubmit(person)
  }

  const validationSchema = Yup.object().shape({
    company: Yup.string().required('Dies ist ein Pflichtfeld'),
    mediaType: Yup.string().required('Dies ist ein Pflichfeld'),
    name: Yup.string().required('Dies ist ein Pflichtfeld'),
    email: Yup.string()
      .email('Bitte eine gültige E-Mail eingeben.')
      .required('Dies ist ein Pflichtfeld'),
    persons: Yup.number(),
    parking: Yup.bool(),
  })

  return (
    <div id="signup_form" className="flex flex-row flex-wrap justify-evenly">
      <h1 className="text-2xl text-left ml-0 md:ml-1 mb-4 sm:mb-6 mr-0 md:mr-8 lg:mr-30 lg:pr-12">
        Anmeldung
      </h1>
      <div className="w-full md:w-2/3">
        <div className="">
          <Formik
            initialValues={{
              company: '',
              mediaType: '',
              name: '',
              email: '',
              persons: 0,
              parking: false,
            }}
            onSubmit={(values, errors) => {
              handleSubmit(values, errors)
            }}
            validationSchema={validationSchema}
          >
            {({
              values,
              errors,
              touched,
              handleSubmit,
              handleChange,
              handleBlur,
            }) => (
              <Form onSubmit={handleSubmit}>
                <div className="flex flex-col">
                  <div className="mt-1 mb-3 md:mb-4">
                    <div className="input-group has-validation">
                      <input
                        type="text"
                        className={` text-input transition ${
                          values.mediaType.length > 0
                            ? 'input-complete font-medium'
                            : 'input-shadow font-normal'
                        } w-full md:w-3/4 lg:text-lg form-control ${
                          touched.company && !!errors.company && 'is-invalid'
                        }`}
                        style={{
                          fontWeight: values.mediaType.length > 0 ? 500 : 400,
                        }}
                        placeholder="Medium/Firma"
                        value={values.company}
                        onChange={handleChange('company')}
                        onBlur={handleBlur('company')}
                      />
                      {/* <div className="invalid-feedback">
                        {errors.company}&nbsp;
                      </div> */}
                    </div>
                  </div>
                </div>
                <div
                  className="mb-5 lg:mb-6 transition"
                  style={{
                    opacity: values.company.length > 1 ? 1 : 0,
                  }}
                >
                  <div className="">
                    <div className="input-group has-validation">
                      <div
                        role="group"
                        aria-labelledby="medientyp"
                        className="flex flex-row justify-evenly md:flex-row w-full md:w-3/4 lg:text-lg"
                      >
                        <label
                          className="mr-4 transition"
                          style={{
                            opacity:
                              values.mediaType.length > 0 &&
                              values.mediaType !== 'TV'
                                ? '0.25'
                                : '1',
                            fontWeight:
                              values.mediaType.length > 0 &&
                              values.mediaType === 'TV'
                                ? 500
                                : 400,
                          }}
                        >
                          <Field
                            type="radio"
                            name="mediaType"
                            value="TV"
                            className={`form-control ${
                              touched.mediaType &&
                              !!errors.mediaType &&
                              'is-invalid'
                            }`}
                            style={{
                              transform: 'scale(1.3)',
                              fontWeight:
                                values.mediaType.length > 0 &&
                                values.mediaType === 'TV'
                                  ? 500
                                  : 400,
                            }}
                            onChange={handleChange('mediaType')}
                            onBlur={handleBlur('mediaType')}
                          />
                          &nbsp;&nbsp;TV
                        </label>
                        <label
                          className="mr-4 transition"
                          style={{
                            opacity:
                              values.mediaType.length > 0 &&
                              values.mediaType !== 'Radio'
                                ? '.25'
                                : '1',
                            fontWeight:
                              values.mediaType.length > 0 &&
                              values.mediaType === 'Radio'
                                ? 500
                                : 400,
                          }}
                        >
                          <Field
                            type="radio"
                            name="mediaType"
                            value="Radio"
                            className={`form-control ${
                              touched.mediaType &&
                              !!errors.mediaType &&
                              'is-invalid'
                            }`}
                            style={{ transform: 'scale(1.3)' }}
                            onChange={handleChange('mediaType')}
                            onBlur={handleBlur('mediaType')}
                          />
                          &nbsp;&nbsp;Radio
                        </label>
                        <label
                          className="mr-4 transition"
                          style={{
                            opacity:
                              values.mediaType.length > 0 &&
                              values.mediaType !== 'Print/Online'
                                ? '.25'
                                : '1',
                            fontWeight:
                              values.mediaType.length > 0 &&
                              values.mediaType === 'PrintOonline'
                                ? 500
                                : 400,
                          }}
                        >
                          <Field
                            type="radio"
                            name="mediaType"
                            value="Print/Online"
                            className={`form-control ${
                              touched.mediaType &&
                              !!errors.mediaType &&
                              'is-invalid'
                            }`}
                            style={{ transform: 'scale(1.3)' }}
                            onChange={handleChange('mediaType')}
                            onBlur={handleBlur('mediaType')}
                          />
                          &nbsp;&nbsp;Print/Online
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="mb-4 lg:mb-6 transition"
                  style={{
                    opacity:
                      values.company.length > 1 && values.mediaType.length > 0
                        ? 1
                        : 0,
                  }}
                >
                  <div className="">
                    <div className="input-group has-validation">
                      <input
                        type="text"
                        className={`text-input transition ${
                          values.mediaType.length > 0 &&
                          ((touched.name && values.name.length > 3) ||
                            values.email.length > 0)
                            ? 'input-complete'
                            : 'input-shadow'
                        } w-full md:w-3/4 lg:text-lg form-control ${
                          touched.name && !!errors.name && 'is-invalid'
                        }`}
                        style={{
                          fontWeight:
                            values.mediaType.length > 0 &&
                            ((touched.name && values.name.length > 3) ||
                              values.email.length > 0)
                              ? 500
                              : 400,
                        }}
                        placeholder="Vor- und Nachname"
                        value={values.name}
                        onChange={handleChange('name')}
                        onBlur={handleBlur('name')}
                      />
                      {/*
                      <div className="invalid-feedback">
                        {errors.name}&nbsp;
</div> */}
                    </div>
                  </div>
                </div>
                <div
                  className="mb-4 lg:mb-6 transition"
                  style={{
                    opacity:
                      values.company.length > 1 &&
                      values.mediaType.length > 0 &&
                      values.name.length > 3 &&
                      values.name.indexOf(' ') !== -1 &&
                      values.name.length > values.name.indexOf(' ') + 2
                        ? 1
                        : 0,
                  }}
                >
                  <div className="">
                    <div className="input-group has-validation">
                      <input
                        type="mail"
                        className={`text-input transition ${
                          touched.persons ||
                          touched.parking ||
                          values.persons > 0 ||
                          values.parking ||
                          submitted
                            ? 'input-complete'
                            : 'input-shadow'
                        }  w-full md:w-3/4 lg:text-lg form-control ${
                          touched.email && !!errors.email && 'is-invalid'
                        }`}
                        style={{
                          fontWeight:
                            touched.persons ||
                            touched.parking ||
                            values.persons > 0 ||
                            values.parking ||
                            submitted
                              ? 500
                              : 400,
                        }}
                        placeholder="E-Mail"
                        value={values.email}
                        onChange={handleChange('email')}
                        onBlur={handleBlur('email')}
                      />
                      {/*}
                      <div className="invalid-feedback">
                        {errors.email}&nbsp;
                      </div> */}
                    </div>
                  </div>
                </div>
                <div
                  className="mb-7 pl-1 transition"
                  style={{
                    opacity:
                      values.company.length > 1 &&
                      values.mediaType.length > 0 &&
                      values.name.length > 3 &&
                      values.name.indexOf(' ') !== -1 &&
                      values.name.length > values.name.indexOf(' ') + 2 &&
                      values.email.length > 3 &&
                      !errors.email
                        ? 1
                        : 0,
                  }}
                >
                  <div className="">
                    <div className="input-group has-validation">
                      Begleitpersonen: &nbsp;{' '}
                      <input
                        type="number"
                        min="0"
                        size={3}
                        className={`w-13 lg:text-lg text-input transition ${
                          touched.persons || touched.parking || values.parking
                            ? 'input-complete'
                            : 'input-shadow'
                        } form-control ${
                          touched.persons && !!errors.persons && 'is-invalid'
                        }`}
                        style={{
                          fontWeight:
                            touched.persons || touched.parking || values.parking
                              ? 500
                              : 400,
                        }}
                        value={values.persons}
                        onChange={handleChange('persons')}
                        onBlur={handleBlur('persons')}
                      />
                      {/* }<div className="invalid-feedback">
                        {errors.persons}&nbsp;
                      </div> */}
                    </div>
                  </div>
                </div>

                <div
                  className="mb-6 transition"
                  style={{
                    opacity:
                      values.company.length > 1 &&
                      values.mediaType.length > 0 &&
                      values.name.length > 3 &&
                      values.name.indexOf(' ') !== -1 &&
                      values.name.length > values.name.indexOf(' ') + 2 &&
                      values.email.length > 3 &&
                      !errors.email
                        ? 1
                        : 0,
                  }}
                >
                  <div className="">
                    <div className="input-group has-validation mb-3">
                      <input
                        key="parking"
                        type="checkbox"
                        className={`form-check-input transition checkbox mr-2 border-0 ${
                          touched.parking && !!errors.parking && 'is-invalid'
                        }`}
                        style={{
                          transform: 'scale(1.5)',
                          boxShadow: 'inset 0 .05em .15em rgba(0, 0, 0, .6)',
                          border: 0,
                        }}
                        name="parking"
                        id="parking"
                        checked={values.parking}
                        onChange={handleChange('parking')}
                        onBlur={handleBlur('parking')}
                        // feedback={errors.parking}
                      />
                      <label className="form-check-label" htmlFor="parking">
                        &nbsp;Ich benötige einen Parkplatz
                      </label>
                    </div>
                  </div>
                </div>
                <div
                  className="mb-5 pt-2 transition"
                  style={{
                    opacity:
                      values.company.length > 1 &&
                      values.mediaType.length > 0 &&
                      values.name.length > 3 &&
                      values.name.indexOf(' ') !== -1 &&
                      values.name.length > values.name.indexOf(' ') + 2 &&
                      values.email.length > 3 &&
                      !errors.email
                        ? 1
                        : 0,
                  }}
                >
                  <div className="">
                    <button
                      type="submit"
                      className={`${submitted ? 'disabled' : ''}`}
                      disabled={submitted}
                    >
                      Anmeldung absenden
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export default SignUpForm
